
import React, { useState, useContext } from "react";
import Slider from "../ReactSlideOut";
import block from "bem-css-modules";

import overlay from "../../styles/components/Overlay.module.scss";
import navigationMenu from "../../styles/components/Navigationmenu.module.scss";
import useTranslation from "next-translate/useTranslation";
// import SearchContext from "./../../context/SearchContext";

function NavigationMenu({ coloseOverlay, isOverlay, searchFilter }) {
  const { t } = useTranslation();

  // const { _type, _sort } = useContext(SearchContext);
  // const [sortValue, setSortValue] = useState(_sort);

  // let sortBy = [
  //   {
  //     name: t("lang_books:bookdetails.filter.mostpopular"),
  //     value: "relevance",
  //   },
  //   {
  //     name: t("lang_books:bookdetails.filter.latestrelease"),
  //     value: "published_date",
  //   },
  //   {
  //     name: t("lang_books:bookdetails.filter.bestrating"),
  //     value: "average_rating",
  //   },
  //   {
  //     name: t("lang_books:bookdetails.filter.booktitle"),
  //     value: "title",
  //   },
  //   {
  //     name: t("lang_books:bookdetails.filter.author"),
  //     value: "authors",
  //   },
  // ];

  // const sortBooks = (i, u) => {
  //   setSortValue(u.value);
  //   searchFilter(_type, u.value);
  // };

  block.setSettings({
    modifierDelimiter: "--",
  });

  const navmenu = block(navigationMenu);

  return (
    <div className={navmenu("")}>
      <Slider
        isOpen={isOverlay}
        onOutsideClick={() => coloseOverlay("none")}
      >
        <div className={`${overlay["SlideModal__header"]} ${overlay["js-slideModalHeader"]}`} >
          <div className={`${overlay["SlideModal__close"]}`} onClick={() => coloseOverlay()}></div>
          {/* <h4 className={`${overlay["SlideModal__title"]}`}> Investors </h4> */}
        </div>

        <div className={overlay["SlideModalContentHolder"]}>
            <div
              className={`${overlay["SlideModalContentItems"]}`}>
              <div className={`${overlay["SlideModalContent__title"]}`}>
              <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.about')}>About us</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.about')}>This is Nextory</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href="https://www.nextory.se/" target="_blank">Try Nextory</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href="https://career.nextory.com/" target="_blank">Career</a></li>
                </ul>
{/* 
                <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.disclaimer')}>Investors</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.disclaimer')}>IPO</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.reports')}>Financial reports</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.calendar')}>Financial calendar</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.share')}>The Share</a></li>
                </ul> */}

                <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.directors')}>Governance</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.directors')}>Board of Directors</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.management')}>Management</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.meetings')}>General Meetings</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.article')}>Article of Association</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.sustain')}>Sustainability</a></li>
                </ul>

                <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.pressreleases')}>Media</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.pressreleases')}>Press releases</a></li>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.subscribe')}>Subscribe</a></li>
                </ul>

                <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href={t('lang_route:investors.contact')}>Contact</a></li>
                </ul>

                <ul className={`${overlay["SlideModalContent__menu"]}`}>
                  <li className={`${overlay["SlideModalContent__list"]}`}><a className={`${overlay["SlideModalContent__item"]}`} href="https://www.nextory.se/" target="_blank">Try Nextory</a></li>
                </ul>

              </div>
            </div>          


        </div>
      </Slider>
    </div>
  );
}
export default NavigationMenu;
