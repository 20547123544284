import React from "react";
import block from "bem-css-modules";
import NavigationMenu from "../NavigationMenu";

import overlay from "../../styles/components/Overlay.module.scss";

function Overlay({ isOverlay, overlayValue, coloseOverlay }) {
  function selectOverlayToShow(overlayToShow) {
    switch (overlayToShow) {
      case "NAVIGATION":
        return (
          <NavigationMenu
            coloseOverlay={coloseOverlay}
            isOverlay={isOverlay}
          />
        );

      default:
        return "";
    }
  }

  block.setSettings({
    modifierDelimiter: "--",
  });

  let overlayActive = {};

  let typeOverlay = typeof overlayValue === "string";

  if (typeOverlay) {
    overlayActive["active"] = true;
  } else {
    overlayActive["active"] = true;
  }

  const ol = block(overlay);

  return (
    <>
      <div className={ol("", { active: isOverlay })} id="overlay">
        <div className={ol("holder")}>{selectOverlayToShow(overlayValue)}</div>
      </div>
    </>
  );
}
export default Overlay;
